















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CatalogService from "@/api/catalogs";
import LoadService from "@/api/loads";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  }
})
export default class AddTransportModal extends Vue {
  trucks: any[] = [];
  trailers: any[] = [];
  truckName = "";
  trailerName = "";
  storage!: any;
  selectedTruck!: any;
  selectedTrailer!: any;
  stamp1 = "";
  stamp2 = "";

  @Prop(Object) readonly load: any;
  @Prop(Boolean) readonly editing: boolean | undefined;

  get filteredTrucks() {
    const trucks = this.trucks
      .filter(option => {
        return (
          ("" + option.eco_num)
            .toString()
            .toLowerCase()
            .indexOf(this.truckName.toLowerCase()) >= 0
        );
      })
      .map(x => `${x.eco_num} - ${x.plates} - ${x.driver_full_name}`);
    return trucks;
  }

  get filteredTrailers() {
    return this.trailers
      .filter(option => {
        const econum = option.eco_num ? option.eco_num : "";
        return (
          econum
            .toString()
            .toLowerCase()
            .indexOf(this.trailerName ? this.trailerName.toLowerCase() : "") >=
          0
        );
      })
      .map(x => `${x.eco_num} - ${x.plate}`);
  }

  @Emit()
  close() {
    return true;
  }

  addTransport() {
    if (!this.selectedTruck || !this.selectedTrailer) {
      this.$buefy.notification.open({
        message: "Necesitas indicar el camión y la caja",
        type: "is-warning"
      });
      return;
    }
    const truckId = this.trucks.find(
      x =>
        (x.eco_num =
          this.selectedTruck.split(" - ")[0] &&
          x.plates == this.selectedTruck.split(" - ")[1])
    ).id;
    const trailerId = this.trailers.find(
      x => x.eco_num == this.selectedTrailer.split(" - ")[0]
    ).id;
    if (truckId && trailerId) {
      const load = { ...this.load };
      load.trailer_id = trailerId;
      load.truck_id = truckId;
      load.stamp_1 = this.stamp1;
      load.stamp_2 = this.stamp2;
      LoadService.update(this.load.id, load).then(response => {
        this.$store.dispatch("LoadModule/replaceLoad", response.data);
        this.$emit("close");
      });
    } else {
      this.$buefy.notification.open({
        message: "Necesitas indicar el camión y la caja",
        type: "is-warning"
      });
    }
  }

  mounted() {
    CatalogService.getTrucks(this.storage.id).then(response => {
      this.trucks = response.data;
      console.log(this.load);
      const truck = this.trucks.find(x => x.id == this.load.truck_id);
      this.selectedTruck = `${truck.eco_num} - ${truck.plates} - ${truck.driver_full_name}`;
      this.truckName = this.load.truck_eco;
    });
    CatalogService.getTrailers().then(response => {
      this.trailers = response.data;
      const trailer = this.trailers.find(x => x.id == this.load.trailer_id);
      this.selectedTrailer = `${trailer.eco_num} - ${trailer.plate}`;
      this.trailerName = this.load.trailer_eco;
    });
    this.stamp1 = this.load.stamp_1;
    this.stamp2 = this.load.stamp_2;
  }
}
