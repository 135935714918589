
























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import LotService from "@/api/lots";

@Component({})
export default class TableLoad extends Vue {
  updateKey = 1;
  @Emit()
  close() {
    return false;
  }

  getDetail(row: any, index: number) {
    if (!Number.isInteger(row.items[0])) {
      return;
    }
    LotService.get(row.id).then(response => {
      const index = this.load.lots.findIndex((x: any) => x.id == row.id);
      this.load.lots[index].items = response.data.items;
      this.updateKey += 1;
    });
  }

  @Prop(Object) readonly load: any;
}
