




































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LoadService from "@/api/loads";

@Component({})
export default class CargaCard extends Vue {
  @Prop(Object) carga: any;

  deleteLoad(loadId: number) {
    this.$buefy.dialog.confirm({
      message: `¿Estas seguro de eliminar carga?`,
      confirmText: "Eliminar",
      cancelText: "Cancelar",
      type: "is-danger",
      onConfirm: () => {
        LoadService.delete(loadId)
          .then(() => {
            this.$buefy.notification.open({
              message: "Se ha eliminado la carga",
              type: "is-success"
            });
            this.$store.dispatch("LoadModule/deleteLoad", loadId);
          })
          .catch(() => {
            this.$buefy.notification.open({
              message: "No ha sido posible eliminar la carga",
              type: "is-warning"
            });
          });
      }
    });
  }

  editLoad(loadId: number) {
    this.$router.push({
      name: "EditarCarga",
      params: { loadId: loadId.toString() }
    });
  }
}
