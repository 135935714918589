import { render, staticRenderFns } from "./CargaCard.vue?vue&type=template&id=fddd0dc6&"
import script from "./CargaCard.vue?vue&type=script&lang=ts&"
export * from "./CargaCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports