












































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import CargaCard from "@/components/cargas/CargaCard.vue";
import LoadService from "@/api/loads";
import LotService from "@/api/lots";
import TableLoad from "@/components/cargas/TableLoad.vue";
import AddTransportModal from "@/components/cargas/AddTransportModal.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    CargaCard,
    TableLoad,
    AddTransportModal
  },
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    }),
    ...mapGetters("LoadModule", {
      loads: "getLoads"
    })
  }
})
export default class Loads extends Vue {
  [x: string]: any;
  storage!: any;
  loads!: any[];
  showPast = false;
  isShowingLots = false;
  isAddingTransport = false;
  viewType = true;
  activeLoad: any = {};
  loading = false;
  isEditing = false;
  isHovering = false;
  loadsHistorial: any[] = [];
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 11
  };
  paginationHistorial: any = {
    total: 0,
    current_page: 1,
    per_page: 6
  };

  openTrailer(props: any) {
    console.log(props);
  }

  showLots(loadId: number): void {
    if (this.viewType) {
      let indexLoad: any = null;
      if (this.showPast) {
        indexLoad = this.loadsHistorial.findIndex(x => x.id == loadId);
      } else {
        indexLoad = this.loads.findIndex(x => x.id == loadId);
      }
      LoadService.get(loadId).then((res: any) => {
        if (this.showPast) {
          this.loadsHistorial[indexLoad].lots = res.data.lots;
        } else {
          this.loads[indexLoad].lots = res.data.lots;
        }
      });
    } else {
      this.isShowingLots = true;
      const activeLoadTemp = this.loads.find(x => x.id == loadId);
      LoadService.get(activeLoadTemp.id).then(res => {
        this.activeLoad = res.data;
        this.activeLoad.lots = this.activeLoad.lots.sort(
          (a: any, b: any) =>
            activeLoadTemp.lots.indexOf(a.id) -
            activeLoadTemp.lots.indexOf(b.id)
        );
      });
    }
  }

  showLotsPast(loadId: number): void {
    this.isShowingLots = true;
    this.activeLoad = this.loadsHistorial.find(x => x.id == loadId);
    LoadService.get(this.activeLoad.id).then(res => {
      this.activeLoad = res.data;
    });
  }

  addTransport(loadId: number, edit = false): void {
    this.isAddingTransport = true;
    if (this.showPast) {
      this.activeLoad = this.loadsHistorial.find(x => x.id == loadId);
    } else {
      this.activeLoad = this.loads.find(x => x.id == loadId);
    }
    this.isEditing = edit;
  }
  editTransport(loadId: number, edit = true) {
    this.addTransport(loadId, edit);
  }

  loadPast(toggleBtn = false) {
    this.isLoading = true;
    if (toggleBtn) {
      this.showPast = !this.showPast;
    }
    LoadService.getHistorial(this.storage.id, this.paginationHistorial).then(
      response => {
        this.loadsHistorial = response.data.data;
        this.paginationHistorial.total = response.data.total;
        this.paginationHistorial.current_page = response.data.current_page;
        this.paginationHistorial.per_page = response.data.per_page;
        this.loading = false;
      }
    );
  }

  getAllLoads() {
    this.loading = true;
    LoadService.getAll(this.storage.id, this.pagination).then(response => {
      this.$store.dispatch("LoadModule/setLoads", response.data.data);
      this.pagination.total = response.data.total;
      this.pagination.current_page = response.data.current_page;
      this.pagination.per_page = response.data.per_page;
      this.loading = false;
    });
  }

  get routeName() {
    return this.$route.name;
  }

  salirCarga(id: number): void {
    this.$buefy.dialog.confirm({
      message:
        "El camión ha salido del almacen, ¿Estas seguro? No hay vuelta atras",
      cancelText: "Cancelar",
      confirmText: "Confirmar",
      onConfirm: () => {
        const activeLoad = this.loads.find(x => x.id == id);
        activeLoad.on_storage = this.$date().format("YYYY-MM-DD");
        LoadService.update(id, activeLoad).then(() => {
          LoadService.getAll(this.storage.id, this.pagination).then(res => {
            this.$store.dispatch("LoadModule/setLoads", res.data.data);
          });
        });
      }
    });
  }

  getAverage(array: any[], key: string, percentage = false, numberDecimal = 2) {
    let result = array.reduce(
      (avg: number, value: any, _: any, { length }: { length: number }) => {
        return avg + (isNaN(value[key]) ? 0 : value[key]) / length;
      },
      0
    );
    if (percentage) {
      result = this.$options.filters
        ? this.$options.filters.percentage(result / 100, numberDecimal)
        : result;
    }
    return result;
  }

  printEmbarque(loadId: number): void {
    let activeLoad = this.loads.find(x => x.id == loadId);
    if (!activeLoad) {
      activeLoad = this.loadsHistorial.find(x => x.id == loadId);
    }
    this.loading = true;
    let lots: any[] = [];
    LotService.getMany(activeLoad.lots.join(",")).then(response => {
      lots = response.data.sort(
        (a: any, b: any) =>
          activeLoad.lots.indexOf(a.id) - activeLoad.lots.indexOf(b.id)
      );
      const data = this.prepareData(activeLoad, lots);
      fetch(`${process.env.VUE_APP_REPORT_API}/pdf/render/template/embarque`, {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(resp => resp.arrayBuffer())
        .then(resp => {
          //set the blog type to final pdf
          const file = new Blob([resp], { type: "application/pdf" });
          this.loading = false;
          // process to auto download it
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "embarque.pdf";
          link.click();
        });
    });
  }

  prepareData(activeLoad: any, lots: any[]) {
    const data = {
      logo_url: `https://nufisar.codepeat.com/images/logo_nogalera.png`,
      logo2_url: `https://nufisar.codepeat.com/images/logo.png`,
      camion_url: `https://nufisar.codepeat.com/images/camion_${
        lots.length > 6 ? "1" : lots.length
      }.png`,
      fecha: this.$date().format("DD/MM/YYYY"),
      lotes_string: lots.map((lot, index) => (lot.lot_id ? lot.lot_id : index)),
      lotes: lots.map(lot => {
        const quebradasAvg = lot.items_data_selected.map((x: any) => {
          return {
            val: (x.broken / x.shell) * 100
          };
        });
        return {
          numero: lot.lot_id,
          variedad: "Western",
          cantidadSacos: lot.items.reduce((sum: number, current: any) => {
            return sum + current.stacks;
          }, 0),
          kg: this.$options.filters
            ? this.$options.filters.number(
                lot.items.reduce((sum: number, current: any) => {
                  return sum + current.weight;
                }, 0),
                2,
                2
              )
            : "",
          kgnum: lot.items.reduce((sum: number, current: any) => {
            return sum + current.weight;
          }, 0),
          rendimiento: `${lot.quality.toFixed(2)}%`,
          humedad: this.getAverage(
            lot.items_data_selected,
            "humidity",
            true,
            1
          ),
          conteoMuestra: Math.round(
            this.getAverage(lot.items_data_selected, "number_of_nuts")
          ),
          quebradas: this.getAverage(quebradasAvg, "val", true, 2)
        };
      }),
      productor: {
        nombre: "Nueces Finas de Santa Rosalia SA de CV",
        direccion: "Gonzalez Ortega No. 3053, Col. Chavira, Camargo, Chih"
      },
      transporte: {
        linea: activeLoad.company,
        placasTractor: activeLoad.plates,
        placasCaja: activeLoad.plate,
        numEco: activeLoad.trailer_eco,
        chofer: activeLoad.driver_full_name,
        precio: "",
        stamp1: activeLoad.stamp_1,
        stamp2: activeLoad.stamp_2
      },
      totalkg: "",
      totalkgNum: 0,
      totalsacos: 0
    };
    data.totalkgNum = data.lotes.map(x => x.kgnum).reduce((a, b) => a + b);
    data.totalkg = data.totalkgNum.toLocaleString();
    data.totalsacos = data.lotes
      .map(x => x.cantidadSacos)
      .reduce((a, b) => a + b)
      .toLocaleString();
    return data;
  }

  deleteLoad(loadId: number) {
    this.$buefy.dialog.confirm({
      message: `¿Estas seguro de eliminar carga?`,
      confirmText: "Eliminar",
      cancelText: "Cancelar",
      type: "is-danger",
      onConfirm: () => {
        LoadService.delete(loadId)
          .then(() => {
            this.$buefy.notification.open({
              message: "Se ha eliminado la carga",
              type: "is-success"
            });
            this.$store.dispatch("LoadModule/deleteLoad", loadId);
          })
          .catch(() => {
            this.$buefy.notification.open({
              message: "No ha sido posible eliminar la carga",
              type: "is-warning"
            });
          });
      }
    });
  }

  editLoad(loadId: number) {
    this.$router.push({
      name: "EditarCarga",
      params: { loadId: loadId.toString() }
    });
  }

  @Watch("pagination.current_page")
  changePage() {
    this.getAllLoads();
  }

  @Watch("paginationHistorial.current_page")
  changePageHistorial() {
    this.loadPast();
  }

  mounted() {
    this.getAllLoads();
  }
}
